export default [
  {
    path: "/",
    text: "početna",
  },
  {
    path: "/o-nama",
    text: "o nama",
  },
  {
    path: "/proizvodi",
    text: "proizvodi",
  },
  {
    path: "/usluge",
    text: "usluge",
  },
  {
    path: "/kontakt",
    text: "kontakt",
  }
]